import type { App } from 'vue'

import { isBoolean } from 'lodash-es'

import moment from 'moment'
import 'moment/dist/locale/zh-cn'
moment.locale('zh-cn')

import accounting from 'accounting'

import { Button, Form, Input, Table, TableColumn, TableColumnGroup } from 'ant-design-vue'

import * as components from '@vue-mfe/components'

export function setupPlugins (app: App<Element>) {
  app.config.globalProperties.$filters = {
    formatDate: (value: moment.Moment | string | number, rule: string | boolean) =>
      value ? moment(value).format(isBoolean(rule) ? 'YYYY-MM-DD HH:mm' : rule || 'YYYY-MM-DD') : '',
    formatNumber: accounting.formatNumber,
    formatMoney: accounting.formatMoney,
    toFixed: accounting.toFixed
  }

  app.use(Button)
  app.use(Form)
  app.use(Input)
  app.use(Table)

  app.component('a-table-column', TableColumn)
  app.component('a-table-column-group', TableColumnGroup)

  Object.entries(components).forEach(([name, component]) => app.component(name, component))
}
