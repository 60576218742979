import type { App } from 'vue'

import { createRouter, createWebHistory } from 'vue-router'
import { toLower, toUpper } from 'lodash-es'

import { Modal } from 'ant-design-vue'

import routes from 'routes'

const ROOT_ROUTERS = ['/admin/authenticate', '/admin/register', '/admin/forget']

export function setupRouter (app: App<Element>) {
  const router = createRouter(
    {
      history: createWebHistory(),
      routes
    }
  )

  router.beforeEach(
    (to, _, next) => {
      Modal.destroyAll()
      // @ts-ignore
      if (to.query.role && toLower(to.query.role) === 'client') {
        let role = to.query.role
        // @ts-ignore
        localStorage.setItem('ENOQUOTE_ROLE', toUpper(role))
        // next(decodeURIComponent(`${to.query.redirect_uri}`))
        // @ts-ignore
        next(decodeURIComponent(to.query.redirect_uri))
      } else {
        let role = localStorage.getItem('ENOQUOTE_ROLE')
        if (!role && !ROOT_ROUTERS.includes(to.fullPath)) next({ name: 'admin-authenticate' })
        //@ts-ignore
        if (to.fullPath === '/') next({ name: `${toLower(role)}` })
        else next()
      }
    }
  )

  app.use(router)
}
