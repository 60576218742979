import { createApp } from 'vue'
import axios from 'axios'
import Cookies from 'js-cookie'

import App from '@container/app.vue'

import { setupPlugins } from '@container/plugin'
import { setupRouter } from '@container/router'
import 'virtual:windi.css'
// import 'virtual:windi-devtools'
import 'virtual:svg-icons-register'
import '@container/assets/index.less'

const app = createApp(App)
setupPlugins(app)
setupRouter(app)
;(async () => {
  localStorage.getItem('ENOQUOTE_ROLE') === 'CLIENT'
    ? await axios
        .get(
          (import.meta.env.VITE_APP_PROFILE === 'prod'
            ? 'https://enocloud.enoch-car.com'
            : 'https://enocloudd.enoch-car.com') + '/enocloud/sso/security/user',
          { withCredentials: true }
        )
        .then(
          (res) => {
            if (res.data.data[0]) {
              Cookies.set('SSO_TOKEN', res.data.data[0].token)
              Cookies.set('ENOCH_TENANT', res.data.data[0].tenant.id)
              Cookies.set('ENOCH_BRANCH', res.data.data[0].branch.id)
            }
          }
        )
        .finally(
          () => {
            app.mount('#app')
          }
        )
    : app.mount('#app')
})()
export default app
